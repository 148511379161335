<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
    <el-backtop :bottom="100"> </el-backtop>
  </div>
</template>
<script>
import Header from '@/components/Header/index'
import Footer from '@/components/Footer/index'
import { mapGetters } from 'vuex'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showFlag: false,
    }
  },
  computed: {
    ...mapGetters(['token']),
  },
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .el-step__head.is-success {
    color: #3fc780;
    border-color: #3fc780;
  }

  .el-step__title.is-success {
    color: #3fc780;
  }
}
</style>

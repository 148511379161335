import request, { uploadFile } from '@/utils/request'
import requestPrint from '@/utils/request-Print'

/**
 * 获取最近店铺信息
 */
export const getStore = (data) => {
  return request({
    url: '/api/shop/lists',
    method: 'POST',
    data,
  })
}

/**
 * 获取打印队列
 * /api/printing.queue/getsQueue
 * data
 * GET
 */
export const getPrintList = (data) => {
  return request({
    url: '/api/printing.queue/getsQueue',
    method: 'GET',
    data,
  })
}

/**
 * 创建打印订单
 * /api/order/buyNowHire
 * GET
 * params
 */
export const getPrintOrderList = (params) => {
  return request({
    url: '/api/order/buyNowHire',
    method: 'GET',
    params,
  })
}

/**
 * 打印文件信息修改 更新价格
 */
export const updatePicPrice = (data) => {
  return request({
    url: '/api/printing.queue/updatePicPrice',
    method: 'POST',
    data,
  })
}

/**
 * 打印文件删除
 */
export const deletePrintFile = (data) => {
  return request({
    url: '/api/printing.Queue/deleteQueue',
    method: 'POST',
    data,
  })
}

/**
 * 打印文件预览--第一步 发送预览文件
 * requestPrint
 * /api/printerbox/Preview
 * POST
 * data
 */
export const sendPreviewPrintFile = (data) => {
  return requestPrint({
    url: '/api/printerbox/Preview',
    method: 'POST',
    data,
  })
}
/**
 * 打印文件预览--第二步 预览文件
 * requestPrint
 * /api/printerbox/PreviewStatus
 * data
 * POST
 */
export const PrintFilePreview = (data) => {
  return requestPrint({
    url: '/api/printerbox/PreviewStatus',
    method: 'POST',
    data,
  })
}

/**
 * 根据店铺id获取打印机数据
 */
export const getPrinterInfo = (params) => {
  return request({
    url: '/api/hire/getHireById',
    method: 'GET',
    params,
  })
}

/**
 * 获取打印机详情
 */
export const getPrinterDetail = (data) => {
  return request({
    url: '/api/printerbox/DeviceInfo',
    method: 'POST',
    data,
  })
}

/**
 * 立即打印
 * /api/order/checkPrint
 * POST
 * data
 */
export const immediatePrint = (data) => {
  return request({
    url: '/api/order/checkPrint',
    method: 'POST',
    data,
  })
}

import request from '@/utils/request'

/**
 * 微信授权登录
 *
 */
export const userLogin = (data) => {
  return request({
    url: '/api/user/login',
    method: 'POST',
    data,
  })
}

/**
 * 获取首页数据
 * /api/page/index
 * POST
 * data
 */
export const TotalList = (params) => {
  return request({
    url: '/api/Page/indexvalue',
    method: 'GET',
    params,
  })
}

/**
 * 获取首页数据
 * /api/page/index
 * POST
 * data
 */
export const getHomeList = (data) => {
  return request({
    url: '/api/page/index',
    method: 'POST',
    data,
  })
}

/**
 * 租赁商品获取 耗材分类列表
 * /api/goods/lists
 * GET
 * params
 */
export const getLeaseList = (params) => {
  return request({
    url: '/api/goods/lists',
    method: 'GET',
    params,
  })
}
/**
 * 热销商品队列获取
 * /api/goods/lists
 * POST
 * data
 */
export const getRexiaoList = (data) => {
  return request({
    url: '/api/goods/lists',
    method: 'POST',
    data,
  })
}

/**
 * 根据id获取商品详情
 * /api/goods/detail
 * params
 * POST
 */
export const getGoodsDetail = (params) => {
  return request({
    url: '/api/goods/detail',
    method: 'POST',
    params,
  })
}

/**
 * 加入购物车
 * /api/cart/add
 * POST
 * data
 */
export const postAddCart = (data) => {
  return request({
    url: '/api/cart/add',
    method: 'POST',
    data,
  })
}

/**
 * 立即购买->确认订单
 * /api/order/buyNow
 * GET
 * params
 */
export const postBuyNow = (params) => {
  return request({
    url: '/api/order/buyNow',
    method: 'GET',
    params,
  })
}

/**
 * 购物车->确认订单
 * /api/order/cart
 * GET
 * params
 */
export const post_CartBuyNow = (params) => {
  return request({
    url: '/api/order/cart',
    method: 'GET',
    params,
  })
}
/**
 * 购物车->发起支付订单
 * /api/order/cart
 * POST
 * data
 */
export const post_CartPayOrder = (data) => {
  return request({
    url: '/api/order/cart',
    method: 'POST',
    data,
  })
}
/**
 * 立即购买-发起支付订单
 * /api/order/buyNow
 * POST
 * data
 */
export const postPayOrder = (data) => {
  return request({
    url: '/api/order/buyNow',
    method: 'POST',
    data,
  })
}

/**
 * 支付判断是否成功
 * /api/user.order/detail
 * POST
 * data
 */
export const postPayJudge = (data) => {
  return request({
    url: '/api/user.order/detail',
    method: 'POST',
    data,
  })
}

/**
 * 取消订单 / 全部退款
 * /api/user.order/cancel
 * POST
 * data
 */
export const postDeleteOrder = (data) => {
  return request({
    url: '/api/user.order/cancel',
    method: 'POST',
    data,
  })
}

/**
 * 单个商品退款
 * /api/user.order/cancel_goods
 * POST
 * data
 */
export const goodsOddRefund = (data) => {
  return request({
    url: '/api/user.order/cancel_goods',
    method: 'POST',
    data,
  })
}

/**
 * 判断订单是否超时
 * /api/order/qrPay
 * POST
 * data
 */
export const postOrderType = (data) => {
  return request({
    url: '/api/order/qrPay',
    method: 'POST',
    data,
  })
}

/**
 * 获取购物车列表
 * /api/cart/lists
 * GET
 * params
 */
export const getCartList = (params) => {
  return request({
    url: '/api/cart/lists',
    method: 'GET',
    params,
  })
}

/**
 * 购物车添加商品数量
 * /api/cart/add
 * POST
 * data
 */
export const goodsNumAdd = (data) => {
  return request({
    url: '/api/cart/add',
    method: 'POST',
    data,
  })
}

/**
 * 购物车减少商品数量
 * /api/cart/sub
 * POST
 * data
 */
export const goodsNumSub = (data) => {
  return request({
    url: '/api/cart/sub',
    method: 'POST',
    data,
  })
}

/**
 * 购物车商品删除
 * /api/cart/delete
 * POST
 * data
 */
export const CartDelete = (data) => {
  return request({
    url: '/api/cart/delete',
    method: 'POST',
    data,
  })
}

/**
 * 余额支付
 * /api/user.order/pay
 * POST
 * data
 */
export const balancePay = (data) => {
  return request({
    url: '/api/user.order/pay',
    method: 'POST',
    data,
  })
}

/**
 * 加入商品收藏
 * /api/user.index/col
 * POST
 * GET
 * data
 */
export const addWishlist = (data) => {
  return request({
    url: '/api/user.index/col',
    method: 'POST',
    data,
  })
}

/**
 * 商品搜索
 * /api/goods/lists
 * GET
 * params
 */
export const getSearch = (params) => {
  return request({
    url: '/api/goods/lists',
    method: 'GET',
    params,
  })
}

/**
 * 耗材分类
 * /api/category/index
 * GET
 * params
 */
export const consumableCategory = (params) => {
  return request({
    url: '/api/category/index',
    method: 'GET',
    params,
  })
}

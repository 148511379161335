import { getToken, setToken, removeToken } from '@/utils/auth'
import { getUserInfo } from '@/api/my-center'

const state = {
  token: getToken(),
  user_id: '',
  user_info: {},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_ID: (state, id) => {
    state.user_id = id
  },
  SET_USER_INFO: (state, userinfo) => {
    state.user_info = userinfo
  },
}

const actions = {
  // 用户id
  setUserId({ commit }, id) {
    commit('SET_USER_ID', id)
    sessionStorage.setItem('user_id', id)
    location.reload()
  },

  //获取个人信息
  async get_UserInfo({ commit }) {
    try {
      const { data } = await getUserInfo()
      commit('SET_USER_INFO', data.userInfo)
      commit('SET_USER_ID', data.userInfo.user_id)
    } catch (error) {
      console.log(error)
    }
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

<template>
  <!-- 底部 -->
  <div class="footer-container">
    <div class="fatfooter">
      <div class="wrapper">
        <div class="fatft-service">
          <div class="item">
            <a href="">
              <img src="@/assets/img/ico/ft-ser1.png" alt="" class="ico" />
              <span>品质保障</span>
            </a>
          </div>
          <div class="item">
            <a href="">
              <img src="@/assets/img/ico/ft-ser2.png" alt="" class="ico" />
              <span>七天无理由退换货</span>
            </a>
          </div>
          <div class="item">
            <a href="">
              <img src="@/assets/img/ico/ft-ser3.png" alt="" class="ico" />
              <span>特色服务体验</span>
            </a>
          </div>
          <div class="item">
            <a href="">
              <img src="@/assets/img/ico/ft-ser4.png" alt="" class="ico" />
              <span>帮助中心</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      圣庄办公服务（海南）有限公司 版权所有 Copyright © 2023 备案号：
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >琼ICP备2023001158号-1
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // gotogoto() {
    //   this.$router.push({ path: 'myCenter/printOrder', query: { index: 1 } })
    // },
  },
}
</script>

<style></style>

import { getStore } from '@/api/print.js'

const state = {
  shop_info: {}, //店铺信息
  shop_list: [],
  latitude: '20.017871',
  longitude: '110.353385',
  print_info: {}, //打印列表付款参数
  print_id: '', //打印机ID
}

const mutations = {
  SET_shop_info(state, info) {
    state.shop_info = info
  },
  SET_shop_list(state, list) {
    state.shop_list = list
  },
  SET_PRINT_INFO(state, print_info) {
    state.print_info = print_info
  },
  SET_latitude(state, latitude) {
    state.latitude = latitude
  },
  SET_longitude(state, longitude) {
    state.longitude = longitude
  },
  SET_print_id(state, print_id) {
    state.print_id = print_id
  },
}

function showPosition(position, { commit }) {
  var latitude = position.coords.latitude
  var longitude = position.coords.longitude
  commit('SET_latitude', latitude)
  commit('SET_longitude', longitude)
  console.log('您的位置是：' + latitude + ', ' + longitude)
}
const actions = {
  //获取定位
  getLocation({ commit }) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        showPosition(position, { commit })
      })
    } else {
      alert('您的浏览器不支持获取位置信息。')
    }
  },

  // 获取最近店铺信息
  async getStore({ commit }) {
    try {
      const obj = {
        latitude: state.latitude,
        longitude: state.longitude,
      }
      const { data } = await getStore(obj)
      const info = {
        shop_name: data?.list[0]?.shop_name,
        shop_phone: data?.list[0]?.phone,
        shop_id: data?.list[0]?.shop_id,
      }
      commit('SET_shop_info', info)
      commit('SET_shop_list', data.list)
      console.log(obj)
      // console.log('最近店铺信息', data.list)
    } catch (error) {
      console.log(error)
    }
  },
  // 设置选中的店铺信息
  setShopInfo({ commit }, info) {
    commit('SET_shop_info', info)
  },
  setPrintInfo({ commit }, info) {
    commit('SET_PRINT_INFO', info)
  },
  setPrintId({ commit }, id) {
    commit('SET_print_id', id)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

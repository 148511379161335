// 个人中心
import request from '@/utils/request'

/**
 * 获取打印订单信息
 */
export const getPrintInfo = (params) => {
  return request({
    url: '/api/user.porder/lists',
    method: 'GET',
    params,
  })
}

/**
 * 查看打印文件列表
 */
export const getPrintFileList = (params) => {
  return request({
    url: '/api/user.porder/detail',
    method: 'GET',
    params,
  })
}

/**
 * 获取打印订单详情
 */
export const postOrderdetail = (params) => {
  return request({
    url: '/api/user.porder/detail',
    method: 'POST',
    params,
  })
}

/**
 * 获取耗材订单信息
 */
export const getConsumableInfo = (params) => {
  return request({
    url: '/api/user.order/lists',
    method: 'GET',
    params,
  })
}

/**
 * 获取耗材订单详情
 */
export const getConsumableDetail = (params) => {
  return request({
    url: '/api/user.order/detail',
    method: 'GET',
    params,
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = (data) => {
  return request({
    url: '/api/user.index/detail',
    method: 'POST',
    data,
  })
}

/**
 * 确认收货
 */
export const confirmReceipt = (data) => {
  return request({
    url: '/api/user.order/receipt',
    method: 'POST',
    data,
  })
}

/**
 * 去评价
 */
export const goEvaluate = (data) => {
  return request({
    url: '/api/user.comment/order',
    method: 'POST',
    data,
  })
}
/**
 * 耗材售后列表
 */
export const refundLists = (params) => {
  return request({
    url: '/api/user.refund/lists',
    method: 'GET',
    params,
  })
}

/**
 * 耗材售后列表
 */
export const refundDetail = (data) => {
  return request({
    url: '/api/user.refund/detail',
    method: 'POST',
    data,
  })
}

/**
 * 申请售后 换货 退货
 */
export const afterSale = (data) => {
  return request({
    url: '/api/user.refund/apply',
    method: 'POST',
    data,
  })
}

/**
 * 申请售后  用户确认发货
 */
export const postRefundType = (data) => {
  return request({
    url: '/api/user.refund/delivery',
    method: 'POST',
    data,
  })
}

/**
 *  获取收货地址列表
 */
export const postAddress = (params) => {
  return request({
    url: '/api/address/lists',
    method: 'GET',
    params,
  })
}

/**
 *  收货地址详情
 */
export const getAddressDetail = (params) => {
  return request({
    url: '/api/address/detail',
    method: 'GET',
    params,
  })
}

/**
 * 获取收货地址所在区域
 */
export const getRegion = (data) => {
  return request({
    url: '/api/index/region_list',
    method: 'POST',
    data,
  })
}

/**
 * 收货地址修改
 */
export const editAddress = (data) => {
  return request({
    url: '/api/address/edit',
    method: 'POST',
    data,
  })
}

/**
 * 设置默认收货地址
 */
export const setDefaultAddress = (data) => {
  return request({
    url: '/api/address/setDefault',
    method: 'POST',
    data,
  })
}

/**
 * 收货地址删除
 */
export const AddressDelete = (params) => {
  return request({
    url: '/api/address/delete',
    method: 'POST',
    params,
  })
}

/**
 * 收货地址删除
 */
export const AddressAdd = (data) => {
  return request({
    url: '/api/address/add',
    method: 'POST',
    data,
  })
}

/**
 * 修改个人信息
 */
export const updataUserInfo = (data) => {
  return request({
    url: '/api/user.index/edit',
    method: 'POST',
    data,
  })
}

/**
 * 获取租赁订单信息
 */
export const getLeaseOrderInfo = (params) => {
  return request({
    url: '/api/user.order/hires',
    method: 'GET',
    params,
  })
}

/**
 * 租赁商品确认收货
 * /api/user.order/Hirereceipt
 * data
 * POST
 */
export const PostLeaseConfirm = (data) => {
  return request({
    url: '/api/user.order/Hirereceipt',
    method: 'POST',
    data,
  })
}

/**
 * 租赁设备管理  - 设备列表
 * /api/user.order/hireslist
 * params
 * GET
 */
export const getHiresList = (params) => {
  return request({
    url: '/api/user.order/hireslist',
    method: 'GET',
    params,
  })
}

/**
 * 租赁设备管理  - 提前退租 - 到期退租
 * /api/recharge/offLease
 * data
 * POST
 */

export const Tq_offLease = (data) => {
  return request({
    url: '/api/recharge/offLease',
    method: 'GET',
    data,
  })
}

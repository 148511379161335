import { getSearch } from '@/api/index.js'

const state = {
  searchKey: '',
  searchList: '',
}

const mutations = {
  SET_searchList(state, list) {
    state.searchList = list
  },
  SET_searchKey(state, value) {
    state.searchKey = value
  },
}

const actions = {
  async search_goodsKey(value, { commit }) {
    try {
      const obj = {
        search: value,
        sortType: 'all',
        sortPrice: '0',
        page: 1,
      }

      const { data } = await getSearch(obj)
      // this.totleData = data?.list
      // this.goods_List = data?.list?.data
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  },

  setValue({ commit }, { v }) {
    commit('SET_searchKey', v)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

const getters = {
  token: (state) => state.user.token,
  userId: (state) => state.user.user_id,
  userInfo: (state) => state.userInfo,
  latitude: (state) => state.print.latitude,
  longitude: (state) => state.print.longitude,
  shopList: (state) => state.print.shop_list,
  shopInfo: (state) => state.print.shop_info,
  shop_name: (state) => state.print.shop_info.shop_name || '暂无店铺信息',
  shop_phone: (state) => state.print.shop_info.shop_phone,
  shop_id: (state) => state.print.shop_info.shop_id,
  printInfo: (state) => state.print.print_info,
  print_id: (state) => state.print.print_id,
  search_key: (state) => state.goods.searchKey,
}
export default getters

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
  },
  {
    path: '/',
    component: () => import('@/views/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    //设备租赁
    path: '/equipmentLease',
    name: 'equipmentLease',
    component: () => import('@/views/equipment-lease'),
  },
  {
    //耗材销售
    path: '/consumableSales',
    name: 'consumableSales',
    component: () => import('@/views/consumable-sales'),
  },
  {
    path: '/goodsDetail/:goods_id',
    name: 'goodsDetail',
    component: () => import('@/views/goods-detail/index'),
  },

  {
    path: '/leaseGoodsDetail/:goods_id',
    name: 'leaseGoodsDetail',
    component: () => import('@/views/goods-detail/index1'),
  },

  {
    path: '/remotePrint',
    name: 'remotePrint',
    component: () => import('@/views/remote-print'),
  },
  {
    // 个人中心
    path: '/myCenter',
    name: 'myCenter',
    component: () => import('@/views/my-center'),
    children: [
      {
        //耗材订单
        name: 'consumablesOrder',
        path: 'consumablesOrder',
        component: () =>
          import('@/views/my-center/order-manage/consumables.vue'),
      },
      {
        //打印订单
        name: 'printOrder',
        path: 'printOrder',
        component: () => import('@/views/my-center/order-manage/print.vue'),
      },
      {
        //租赁订单
        name: 'leaseOrder',
        path: 'leaseOrder',
        component: () => import('@/views/my-center/order-manage/lease.vue'),
      },
      {
        //账户信息
        name: 'UserInfo',
        path: 'UserInfo',
        component: () =>
          import('@/views/my-center/account-manage/user-info.vue'),
      },
      {
        //收货地址
        name: 'Myaddress',
        path: 'address',
        component: () => import('@/views/my-center/account-manage/address.vue'),
      },
      {
        //我的收藏
        name: 'goodsCollect',
        path: 'goodsCollect',
        component: () =>
          import('@/views/my-center/account-manage/goods-collect.vue'),
      },
      {
        //耗材售后
        name: 'consumablesAs',
        path: 'consumablesAs',
        component: () =>
          import('@/views/my-center/after-sale-manage/consumables-as.vue'),
      },
      {
        //租赁售后
        name: 'leaseAs',
        path: 'leaseAs',
        component: () =>
          import('@/views/my-center/after-sale-manage/lease-as.vue'),
      },
      {
        //租赁售后
        name: 'deviceMng',
        path: 'deviceMng',
        component: () =>
          import('@/views/my-center/after-sale-manage/device-mng.vue'),
      },
      ,
    ],
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order'),
  },
  {
    path: '/leaseOrderInfo',
    name: 'leaseOrderInfo',
    component: () => import('@/views/order/lease-index.vue'),
  },
  {
    path: '/consumablesPay',
    name: 'consumablesPay',
    component: () => import('@/views/order/consumables-pay.vue'),
  },
  {
    path: '/printPay',
    name: 'printPay',
    component: () => import('@/views/order/print-pay.vue'),
  },
  {
    // 付款成功
    path: '/finishPay',
    name: 'finishPay',
    component: () => import('@/views/order/finish-pay'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/cart'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/goods-search'),
  },
]

const createRouter = () =>
  new Router({
    mode: 'hash', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

export default router

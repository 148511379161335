import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import router from '@/router'
//引入进度条
import nprogress from 'nprogress'
//引入相关进度条的样式
import 'nprogress/nprogress.css'

// create an axios instance
const service = axios.create({
  baseURL: 'https://cloud.hiant.top/index.php', // url = base url + request url

  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
})

// request interceptor请求拦截器
service.interceptors.request.use(
  (config) => {
    // nprogress.start()
    // do something before request is sent
    // if (store.getters.token) {
    //   // let each request carry token

    //   config.headers['Authorization'] = getToken();
    // }

    const obj = {
      token: getToken(),
      wxapp_id: 10001,
    }
    // console.log('token', getToken())

    if (config.data) {
      config.data = { ...config.data, ...obj }
    } else {
      config.params = { ...config.params, ...obj }
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // nprogress.done()
    if (res.code == 0) {
      console.log(res.msg)
      Message.error(res.msg)
      return Promise.reject(new Error(res.msg || 'Error'))
    } else if (res.code == -1) {
      return MessageBox.alert('您的登录已失效，需要重新登录', '重新登陆', {
        confirmButtonText: '重新登陆',
        type: 'warning',
      })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          return router.replace({ path: '/login' })
        })
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

// 封装的传输方法
export const upload_A_File = (url, data, onUploadProgress) => {
  return service({
    url: url,
    method: 'post',
    data: data,
    onUploadProgress: onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default service

<template>
  <div class="hearder-container">
    <div class="header-top">
      <div class="left">
        <div class="r-item" @click="routerHome">
          <img src="@/assets/header/shouye.png" alt="" />
          <div class="text" style="cursor: pointer">商城首页</div>
        </div>
        <div class="middle"></div>
        <router-link to="/home" class="r-item">
          <div class="text">商家中心</div>
        </router-link>
      </div>

      <div class="right">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link text" style="cursor: pointer">
            {{ userName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="/myCenter/UserInfo"
              style="white-space: nowrap"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item command="/login" style="white-space: nowrap"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <router-link to="/home" class="r-item">
          <div class="text">消息 (0)</div>
        </router-link>
        <!-- <div @click="exit" class="r-item" style="cursor: pointer">
          <div class="text">退出</div>
        </div> -->
        <router-link to="/cart" class="r-item">
          <img src="@/assets/header/gouwuchekong.png" alt="" />
          <div class="text">购物车</div>
        </router-link>
        <router-link to="/myCenter/consumablesOrder?index=0" class="r-item">
          <img src="@/assets/header/wodedingdan.png" alt="" />
          <div class="text">我的订单</div>
        </router-link>
        <router-link to="/myCenter/goodsCollect?index=7" class="r-item">
          <img src="@/assets/header/wodeshoucang.png" alt="" />
          <div class="text">我的收藏</div>
        </router-link>
        <router-link to="/home" class="r-item">
          <img src="@/assets/header/bangzhuzhongxin.png" alt="" />
          <div class="text">帮助中心</div>
        </router-link>
      </div>
    </div>
    <div class="header-middle">
      <div class="top-nav">
        <div @click="routerHome" class="log" style="cursor: pointer">
          <img src="@/assets/img/logo-sz.png" />
        </div>
        <div class="nav-item">
          <div
            :class="activeIndex == '3' ? 'active' : ''"
            @click="routerHome()"
            class="item"
          >
            首页
          </div>
          <div
            class="item"
            :class="activeIndex == index ? 'active' : ''"
            v-for="(item, index) in navShopList"
            :key="index"
            @click="routerSkip(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="search-item">
          <!-- <div class="left-select">
            <el-select v-model="value" placeholder="商品">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <el-input
            class="search-value"
            v-model="SearchValue"
            :placeholder="placeholderValue"
            @keyup.enter.native="goodsSearchList"
          ></el-input>
          <div class="btn-search">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="goodsSearchList"
              >搜索</el-button
            >
          </div>
          <div class="right-service">
            <img src="@/assets/img/kefu.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserInfo } from '@/api/my-center'
export default {
  data() {
    return {
      activeIndex: 3,
      SearchValue: '',
      value: '',
      options: [
        {
          value: '打印机耗材',
          label: '打印机耗材',
        },
        {
          value: '打印机租赁',
          label: '打印机租赁',
        },
      ],
      navShopList: [
        {
          name: '远程打印',
          url: 'remotePrint',
        },
        {
          name: '设备租赁',
          url: 'equipmentLease',
        },
        {
          name: '耗材销售',
          url: 'consumableSales',
        },
      ],
      userInfo: {},
      userName: '亲，请登录',
      placeholderValue: '请输入商品名称',
    }
  },
  mounted() {
    this.get_UserInfo()
    if (sessionStorage.getItem('searchValue')) {
      this.placeholderValue = sessionStorage.getItem('searchValue')
    }
  },
  computed: {
    ...mapGetters(['token']),
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    handleCommand(command) {
      if (command == '/login') {
        this.userName = '亲，请登录'
        sessionStorage.removeItem('token')
      }
      this.$router.push({ path: command, query: { index: 3 } })
    },
    async get_UserInfo() {
      try {
        if (!this.token) {
          return
        }
        const { data } = await getUserInfo({})
        this.userInfo = data?.userInfo
        this.userName = data?.userInfo?.nickName
        console.log(this.userName)
        // console.log(data.userInfo)
      } catch (error) {
        console.log(error)
      }
    },
    goodsSearchList() {
      if (!this.SearchValue.toString().trim().length) {
        return this.$message.warning('搜索关键词不能为空')
      }
      const v = this.SearchValue.toString().trim()
      // this.$store.dispatch('goods/search_goodsKey', { value: v })
      sessionStorage.setItem('searchValue', v)
      this.$store.dispatch('goods/setValue', { v })
      this.$router.push({
        name: 'search',
      })
    },
    routerSkip(item, index) {
      this.activeIndex = index
      this.$router.push({ name: item.url })
    },
    routerHome() {
      this.activeIndex = '3'
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style lang="scss" scoped>
.hearder-container {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 300px;
  background-color: #f9f9f9;

  .left {
    display: flex;

    .middle {
      border-right: 1px solid #d9d9d9;
      margin: 0 10px;
    }
  }

  .right {
    display: flex;
    margin-left: 100px;
    .r-item {
      margin-left: 20px;
    }
  }

  .r-item {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
  .el-dropdown-link {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
  }
  .text {
    white-space: nowrap;
    text-align: center;
    color: #a5a5a5;
    &:hover {
      color: red;
    }
  }
}

.header-middle {
  .top-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 100px;
    border-bottom: 1px solid #3fc780;
  }
  .log {
    width: 260px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /* 导航栏选项 */
  .nav-item {
    display: flex;
  }

  .nav-item .item {
    display: inline-block;
    white-space: nowrap;
    font-size: 20px;
    margin-left: 80px;
    cursor: pointer;
    font-weight: bold;
  }

  .nav-item .item:hover {
    color: #3fc780;
  }
  .nav-item .item a:hover {
    color: #3fc780;
  }

  /* 右侧输入框 */
  .search-item {
    display: flex;
    align-items: center;
    margin-left: 30px;
    width: 30%;

    .left-select {
      min-width: 122px;
    }
  }

  ::v-deep .search-value .el-input__inner {
    border-radius: 8px 0px 0px 8px;
    // border-right: 0px solid #fff;
  }

  ::v-deep .left-select .el-input__inner {
    border-radius: 8px 0px 0px 8px;
  }

  ::v-deep .btn-search .el-button {
    border-radius: 0px 8px 8px 0px;
  }

  .right-service img {
    width: 40px;
    height: 40px;
    margin-left: 40px;
    cursor: pointer;
  }
}

.active {
  color: #3fc780;
}
</style>
